
@tailwind base;

@tailwind components;

@tailwindcss/forms;
@tailwindcss/typography;
@tailwindcss/aspect-ratio;

.select2-container--default .select2-selection--multiple {
  background-color: rgba(251, 253, 248, 1);
  border-color: rgba(172, 213, 84, 1);
  border-radius: 0.5rem;
}
.select2-container--default .select2-selection--multiple:focus {
  border-color: rgba(6, 165, 73, 1);
}

/* Start Dashboard */
.fa-sort-asc::before {
  content: "↑";
  font-weight: bolder;
  font-size: x-large;
}

.fa-sort-desc::before {
  content: "↓";
  font-weight: bolder;
  font-size: x-large;
}
/* End Dashboard */

.select2-container .select2-selection {
  height: 37px;
  margin-bottom: 1px;
}

@tailwind utilities;
h1,h2,h3,h4 {
  font-family: 'Karma', serif;
}
a,p,figcaption,caption,td {
  font-family: 'Quicksand', sans-serif;
}
[x-cloak] {
  display: none;
}

#scan-button {
  z-index: 100;
}

#scan-barcode {
  width: 0;
  height: 0;
  position: static;
  margin-left: -50px;
  margin-top: 10px;
  z-index: 10;
  /*resize:none;*/
}

.text-xxs {
  font-size: 0.50rem;
  line-height: 0.50rem;
}


